export const systemColors = {
  blue: '#4264fd',
  green: '#3db460',
  red: '#ff586d',
  kapital: '#b30838',
  yellow: '#ffb900',
  black: '#121212',
  auburnRed: '#d93d04',
  lightRed: '#ffe6e5'
};

export const promotionColors = {
  bump: '#78c91d',
  vip: '#ff3d00',
  premium: '#ff9f2b'
};

export const gradient = {
  basic: 'linear-gradient(315deg, #e7e7e7 0%, #f6f7fa 100%)',
  platinum: 'linear-gradient(315deg, #e2e3ea 0%, #f0edff 100%)',
  silver: 'linear-gradient(315deg, #d6d6d6 0%, #ededed 100%)',
  gold: 'linear-gradient(320deg, #decfa7 -8.11%, #ede6d2 69.11%)',
  promo: 'linear-gradient(90deg, #00c850 0%, #52cb98 50%, #37c7bf 100%)'
};

export const fontWeight = {
  thin: 400,
  medium: 500,
  bold: 700
};

export const defaultStyles = {
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  borderRadius: '14px',
  gradient,
  baseFontSize: '16px'
};
