import { gql } from '@apollo/client';

export const companyNameQuery = gql`
  query CompanyNameQuery {
    profile {
      ... on CompanyProfile {
        name
        id
        phone
      }
    }
  }
`;

export const userDataQuery = gql`
  query UserDataQuery {
    profile {
      ... on CompanyProfile {
        name
        id
        phone
        email
        contactName
        targetType
      }
    }
  }
`;

export const profileBalanceQuery = gql`
  query CompanyNameQuery {
    profile {
      ... on CompanyProfile {
        paidItemsBalance
      }
    }
  }
`;

const temp = 'just for eslint';

export default temp;
