import { lightTheme } from './light';
import { defaultStyles } from '../constants';
import { getButtonColors } from '../helpers/getButtonColors';

export const getCurrentTheme = (currentTheme = lightTheme) => {
  return {
    ...defaultStyles,
    ...currentTheme,
    button: getButtonColors(currentTheme.colors)
  };
};
