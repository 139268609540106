import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Icon = ({
  IconComponent,
  width = '15',
  height = '15',
  color = 'blue',
  indent = true,
  lgIndent = false,
  pathColor,
  iconNeedColorChange = false
}) => {
  const iconClasses = cn('svg-icon', `svg-icon--${color}`, {
    [`svg-icon--path-${pathColor}`]: pathColor,
    'svg-icon--indent': indent,
    'svg-icon--indent--large': lgIndent,
    'svg-icon--icon-color-change': iconNeedColorChange
  });

  if (!IconComponent) {
    return;
  }

  return (
    <IconComponent width={width} height={height} className={iconClasses} />
  );
};

Icon.propTypes = {
  IconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  indent: PropTypes.bool,
  lgIndent: PropTypes.bool,
  pathColor: PropTypes.string,
  iconNeedColorChange: PropTypes.bool
};

export default Icon;
