import { gql } from '@apollo/client';

export function seoQuery(path) {
  const name = `SeoQuery_2_${path}`.replace(/[^_0-9A-Za-z]/g, ''); // http://spec.graphql.org/June2018/#sec-Names

  return gql`
    query ${name}($path: Path!){
      seo(path: $path) {
        id
        title
        description
        h1
      }
    }
  `;
}

export function getItemSeoQuery(id) {
  const name = `GetCurrentItemSeo_${id}`;
  return gql`
    query ${name}($id: ID!) {
      item(id: $id) {
        id
        metaTags {
          name
          content
        }
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
