/* eslint-disable */
const countersLoader = {
  li: function(){
    new Image().src = "//counter.yadro.ru/hit?r"+
      escape(document.referrer)+((typeof(screen)=="undefined")?"":
        ";s"+screen.width+"*"+screen.height+"*"+(screen.colorDepth?
        screen.colorDepth:screen.pixelDepth))+";u"+escape(document.URL)+
      ";"+Math.random();
  },
  tagManager: function(gtmContainerId){
    if (typeof gtmContainerId !== 'undefined' && gtmContainerId) {
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer',gtmContainerId);
    }
  }
};

export default countersLoader;
