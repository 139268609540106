import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import langdomain from '../locales/subdomainLang';

import az from '../locales/az.json';
import ru from '../locales/ru.json';

const languageDetector = new LanguageDetector();

languageDetector.addDetector(langdomain);

i18n.use(languageDetector).use(intervalPlural);

if (!i18n.isInitialized) {
  i18n.init({
    fallbacks: true,
    fallbackLng: 'az',
    nonExplicitWhitelist: true,
    load: 'languageOnly',
    debug: false,
    resources: {
      az: {
        translation: az
      },
      ru: {
        translation: ru
      }
    },
    languages: ['az', 'ru'],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
    detection: {
      order: ['langdomain']
    }
  });
}

export default i18n;
