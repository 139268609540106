import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  appVersion: process.env.APP_VERSION,
  logger: null,
  consoleBreadcrumbsEnabled: false,
  onError(event) {
    const reportApp = event.app;
    const requestUrl = event.request.url;
    if (requestUrl.match(/(?:.+\.)?bina\.az/g)) {
      reportApp.releaseStage = 'production';
      reportApp.notifyReleaseStages = 'production';
    } else {
      reportApp.releaseStage = process.env.VERCEL_GITHUB_BRANCH;
      reportApp.notifyReleaseStages = process.env.VERCEL_GITHUB_BRANCH;
    }
  },
  plugins: [new BugsnagPluginReact()]
});

export default Bugsnag;
