import { createStore } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import combinedReducer from '../reducers';

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload
    };
    return nextState;
  }
  return combinedReducer(state, action);
};

const makeStore = () => {
  return createStore(reducer);
};

export default createWrapper(makeStore);
