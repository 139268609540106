import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import { getCurrentTheme } from './index';
import { useBridgeState } from '../../bridge';

export const ThemeWrapper = ({ children }) => {
  const theme = getCurrentTheme();
  const { isWebView, insets } = useBridgeState();

  theme.isWebView = isWebView;
  theme.webview = { insets };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
