import React, { useContext } from 'react';
import {
  BridgeContext,
  BridgeAPIContext,
  BridgeProvider
} from './BridgeProvider';

export const useBridgeState = () => {
  return useContext(BridgeContext);
};

export const useBridgeAPI = () => {
  return useContext(BridgeAPIContext);
};
export const withBridgeState = Component => {
  const BridgeStateWrapper = props => {
    const state = useBridgeState();
    return <Component bridgeState={state} {...props} />;
  };

  BridgeStateWrapper.getInitialProps = async ctx => {
    let appProps = {};
    if (Component.getInitialProps) {
      appProps = await Component.getInitialProps(ctx);
    }
    return { ...appProps };
  };

  return BridgeStateWrapper;
};

export const withBridgeAPI = Component => {
  const BridgetAPIWrapper = props => {
    const state = useBridgeAPI();
    return <Component bridgeAPI={state} {...props} />;
  };

  return BridgetAPIWrapper;
};

export default BridgeProvider;
