import { gql } from '@apollo/client';

export function locationGroupsQuery({ ids, cityId }) {
  const idsString = ids.join('_');
  const name = `LocationGroupsQuery_ids_${idsString}_cityId_${cityId}`;
  return gql`
    query ${name}($ids: [ID!], $cityId: ID) {
      locationGroups(filter: { ids: $ids }) {
        id
        name
        locations(filter: { cityId: $cityId }) {
          id
          name
          fullName
          parent {
            id
            fullName
          }
        }
      }
    }
  `;
}

export function locationGroupsLightQuery({ ids, cityId }) {
  const idsString = ids.join('_');
  const name = `LocationGroupsLightQuery_ids_${idsString}_cityId_${cityId}`;
  return gql`
    query ${name}($ids: [ID!], $cityId: ID) {
      locationGroups(filter: { ids: $ids }) {
        id
        locations(filter: { cityId: $cityId }) {
          id
        }
      }
    }
  `;
}
