import { gql } from '@apollo/client';

export const categoriesQuery = gql`
  query CategoriesQuery {
    categories {
      id
      name
      pluralName
      slug
      photosRequired
      children {
        id
        name
        pluralName
        slug
        photosRequired
      }
    }
  }
`;

const temp = 'just for eslint';

export default temp;
