export const setSuffix = (i18n, val, prefix) => {
  const count = parseInt(val, 10);
  const lang = i18n.language;
  const suffix = i18n.services.pluralResolver.getSuffix(lang, count);

  return `${prefix}${suffix || ''}`;
};

export const getLangDefinition = lang => {
  switch (true) {
    case lang === 'ru':
      return lang;
    default:
      return 'az';
  }
};

export const getReversedLangDefinition = lang => {
  switch (lang) {
    case 'ru':
      return 'az';
    default:
      return 'ru';
  }
};
