export const getButtonColors = ({ background, systemColors }) => ({
  default: {
    color: background.primary,
    background: systemColors.blue
  },
  edit: {
    color: systemColors.blue,
    background: background.primary,
    border: `1px solid ${systemColors.blue}`
  },
  white: {
    color: systemColors.primary,
    background: background.primary
  },
  delete: {
    color: systemColors.red,
    background: background.primary,
    border: `1px solid ${systemColors.red}`
  },
  green: {
    color: background.primary,
    background: systemColors.green
  },
  kapitalBank: {
    color: background.primary,
    background: '#b30838',
    fontWeight: 500
  },
  link: {
    color: systemColors.blue,
    background: 'initial'
  }
});
