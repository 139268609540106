import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import Logo from '../../public/static/images/brown-logo.svg';
import Plus from '../../public/static/images/green-plus.svg';
import CloseWebview from '../../public/static/images/close-webview.svg';
import {
  setChosenLocationNames,
  setCurrentState,
  setLocationIds,
  switchTab,
  toggleAddNewItemModal,
  togglePromotionModalView
} from '../../actions';
import { useBridgeAPI, useBridgeState } from '../../bridge';
import useNewItemLocalState from '../../hooks/useNewItemLocalState';
import BackButton from '../BackButton';

const WhiteHeader = ({
  noBorder,
  headerTitle = '',
  isFixedHeader,
  isShowBackButton,
  isShowCloseButton
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { isWebView } = useBridgeState();
  const { closeWebview } = useBridgeAPI();
  const { setFirstViewValue } = useNewItemLocalState();
  const headerClassNames = classNames('white-header', {
    'white-header--main': !headerTitle && !isShowBackButton,
    'white-header--fixed': isFixedHeader,
    'white-header--center': isShowBackButton,
    'white-header--no-border': noBorder
  });

  const clickHandle = () => {
    dispatch(switchTab(0));
    dispatch(setChosenLocationNames([]));
    dispatch(setLocationIds([]));
    dispatch(setCurrentState(null));
    dispatch(togglePromotionModalView(false));

    return router.push('/');
  };

  const openCreateNewItemForm = () => {
    dispatch(toggleAddNewItemModal(true));
    setFirstViewValue('false');
  };

  const getIcon = () => {
    switch (true) {
      case isWebView:
        if (!isShowCloseButton) return null;
        return (
          <button className="white-header__webview-btn" onClick={closeWebview}>
            <i>
              <Icon
                IconComponent={CloseWebview}
                width={15}
                height={15}
                indent={false}
                color={'#a9a39e'}
              />
            </i>
          </button>
        );
      case isShowBackButton:
        return null;
      default:
        return (
          <div
            role="button"
            tabIndex={0}
            onClick={openCreateNewItemForm}
            onKeyPress={openCreateNewItemForm}
            className="white-header__new-btn"
            data-stat="header-new-btn"
          >
            <Icon IconComponent={Plus} width={30} height={30} indent={false} />
          </div>
        );
    }
  };

  return (
    <div className={headerClassNames} id="white-header">
      {isShowBackButton && <BackButton />}
      {headerTitle ? (
        <span className="white-header__title">{headerTitle}</span>
      ) : (
        <>
          <a
            data-cy="white-header-logo"
            data-stat="header-logo"
            onClick={clickHandle}
            onKeyPress={clickHandle}
            role="button"
            tabIndex="-1"
          >
            <Icon IconComponent={Logo} width={85} height={19} />
          </a>
          {getIcon()}
        </>
      )}
    </div>
  );
};

WhiteHeader.propTypes = {
  noBorder: PropTypes.bool,
  headerTitle: PropTypes.string,
  isFixedHeader: PropTypes.bool,
  isShowBackButton: PropTypes.bool,
  isShowCloseButton: PropTypes.bool
};

export default WhiteHeader;
