import { promotionColors, systemColors } from '../constants';

export const lightTheme = {
  colors: {
    text: {
      primary: '#2f1f19',
      secondary: '#98918a',
      basic: '#505050',
      platinum: '#7a66de',
      silver: '#888686',
      gold: '#9c8651'
    },
    background: {
      primary: '#fff',
      secondary: '#f6f4f1',
      tertiary: '#f8f8f8'
    },
    border: { primary: '#eae5e1' },
    systemColors: {
      ...systemColors,
      brand: '#946952',
      lightBlue: '#dae8ff',
      lightRed: '#ffe6e5'
    },
    promotion: promotionColors
  },
  mobile: {
    tabBar: {
      height: 53
    }
  }
};
